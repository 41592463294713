.button-loader {
    display: inline-block;
    position: relative;
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #009934;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    margin-right: 5px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  