body{
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color:#EFF2ED;
}
html{
  overflow-x: hidden;}
@font-face {
  font-family: 'IvyJournal-Bold';
  src: url('./fonts/IvyJournal-Bold.ttf');
  
}
@font-face {
  font-family: 'IvyJournal-Regular';
  src: url('./fonts/IvyJournal-Regular.ttf');
}
@font-face {
  font-family: 'IvyJournal-SemiBoldItalic';
  src: url('./fonts/IvyJournal-SemiBoldItalic.ttf');
}
a{
  text-decoration: none !important;
}
a p{
  color: #000000;
}
.shadow-box{
  box-shadow: 1px 2px 3px 0px #dbd3d3;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}
.font-Bold-Ivy {
  font-family: 'IvyJournal-Bold';
}
.font-DM-Sans {
  font-family: 'DM Sans', sans-serif;
}
.vr {
  height: 29px;
  opacity: 1;
  border: 1px solid #FFFFFF;
}
.bg-img {

  background: #A1E2C1;
  background-position: center;
  background-size: cover;
}
.bg-imge {
  background: #A1E2C1;
  background-position: center;
  background-size: cover;
}
.inner_imges {
  top: -1px;
  left: 55%;
  width: 45% !important;
}
.inner_imgees {
  top: 35px;
  right: 0%;
  width: 11% !important;
}
.inner_imgee {
  top: -174px;
  left: 0%;
  width: 18% !important;
}
.iner_img {
  top: -28px;
  width: 50%;
  right: -141px;
  z-index: 1;
}
.iner_imgs {
  top: 0px;
  width: 46.5%;
  right: -112px;
  z-index: 1;
}
.bg-img1 {
  background: #EFF2ED;
  background-position: center;
  background-size: cover;
}
.navbar-nav .nav-link.active {
  border-bottom: 4px solid #FFF29C;
  color: black !important;
}
.navbar-nav .nav-link {
 color: black !important;
}
.w-320{
  width: 320px !important;
}
.btn-fill {
  background: #FFF29C !important;
  border-radius: 9px !important;
  border: none !important;
  padding: 9px 18px !important;
  color: black !important;
  font-weight: 600 !important;
  min-width: 130px !important;
}
.btn-fill-empty {
  background: transparent;
  border-radius: 9px !important;
  border: 1px solid black!important;
  padding: 9px 18px !important;
  color: black !important;
  font-weight: 600 !important;
}
.btn-Pill {
  background: #000000 !important;
  border-radius: 12px !important;
  padding: 9px 18px !important;
  color: white !important;
  font-weight: 600 !important;
}
.btn-fill:hover {
  background: #FFF29C;
  border-radius: 9px;
}
.green_color {
  color: #009934;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: white;
  /* border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius); */
}
.transform {
  transform: translate(10px, -95px);
  background: #FFFFFF;
  box-shadow: 0px 4px 32px rgba(226, 222, 222, 0.1);
  border-radius: 22px;
}
.inner_line {
  position: relative;
}
.inner_line::after {
  width: 3px;
  height: 90px;
  background-color: #DBDBDB;
  content: '';
  position: absolute;
  display: flex;
  border-radius: 18px;
  border: 1px solid #DBDBDB;
  top: 37px;
  right: 14px;
}
.bg-img2 {
  background-color:
      transparent;
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color:
      transparent !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: none !important;
}
.accordion-button {
  color: #000000 !important;
  /* background-color: var(--bs-accordion-btn-bg); */
}
.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: #7de1b312;
}
.text_black {
  justify-content: end;
  /* color: #272525; */
  border: 1px solid #000000;
  /* height: 10px; */
  width: 65%;
}
.accordion-button::after {
  margin-left: 14px !important;
  font-family: 'fontawesome' !important;
  content: "\f063" !important;
}
.accordion-button::after {
  background-image: none !important;
}
.accordion-button:not(.collapsed)::after {
  background-image: none !important;
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button:focus {
  z-index: 3;
  border-color: "";
  outline: 0;
  box-shadow: none !important;
}
.accordion-item .accordion-button {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(0.25turn, rgb(0, 0, 0), rgb(0, 0, 0), rgba(209, 209, 209, 0));
  border-image-slice: 1;
}
.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}
.step-wrapper {
  padding: 20px 0;
  display: none;
}
.step-wrapper.active {
  display: block;
}
.step-indicator {
  border-collapse: separate;
  display: table;
  margin-left: 0px;
  position: relative;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  padding-left: 0;
  padding-top: 20px;
}
.step-indicator li {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}
.step-indicator li:after {
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 32px;
}
.step-indicator li:after {
  left: 50%;
}
.step-indicator li:last-child:after {
  display: none;
}
.step-indicator li.active .step {
  border-color: #4183D7;
  color: #4183D7;
}
.step-indicator li.active .caption {
  color: #4183D7;
}
.step-indicator li.complete:after {
  background-color: #87D37C;
}
.step-indicator li.complete .step {
  border-color: #87D37C;
  color: #87D37C;
}
.step-indicator li.complete .caption {
  color: #87D37C;
}
.step-indicator .step {
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  line-height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
}
.step-indicator .step:hover {
  cursor: pointer;
}
.step-indicator .caption {
  color: #ccc;
  padding: 11px 16px;
}
.ineer-line {
  background: #A1E2C1;
  box-shadow: 0px 4px 12px #85C9A6;
}
.inneer-box {
  background: #FFFFFF;
  border-radius: 22px;
}
input {
  border: none;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #009934 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}
  .form-select{
      color: #009934 !important;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
  }
  .inner-imges{
      width: 33.3px;
      height: 33.3px;
  }
  .input_field {
      padding: 12px !important;
      background-color: white !important;
      border-left: none !important;
  }
  /* -------------Common-class---------- */
  .font_20 {
  font-size: 20px;
}
.font_22 {
  font-size: 22px;
}
.font_12 {
  font-size: 12px;
}
.font_24 {
  font-size: 24px;
}
.font_500 {
  font-weight: 500;
}
.font_600 {
  font-weight: 600;
}
.font_52 {
  font-size: 41px;
}
.font_36{
  font-size: 36px;
}
.font_55 {
  font-size: 55px;
}
.font_40 {
  font-size: 40px;
}
.font_18 {
  font-size: 18px;
}
.font_16 {
  font-size: 16px;
}
.font_14 {
  font-size: 14px;
}
.font_28 {
  font-size: 28px;
}
.font_38 {
  font-size: 38px;
}
.font_32 {
  font-size: 32px;
}
.font_30 {
  font-size: 30px;
}
.font_24 {
  font-size: 24px;
}
.white_color {
  color: #FFFFFF;
}
.light {
  color: #E7E9EB;
}
.font_700 {
  font-weight: 700;
}
.next-btn {
  text-align: start !important;
  transform: translate(10px, -16px);
}
.step-wrapper {
  text-align: left;
}
.btn-pill {
  transform: translate(-40px, -111px);
  background: #FFF29C;
  border-radius: 9px;
  border: 1px solid #FFF29C;
  padding: 9px 18px;
  color: black;
  font-weight: 600;
}
.btn-pill:hover {
  border: 1px solid #FFF29C;
}
.displayNone {
  display: none !important;
}
.myButton {
  background: #A1E2C1;
  box-shadow: 0px 15px 32px #7ECDA5;
  border-radius: 22px;
}
.clicked {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
}
.chose_file {
  text-align: center;
  background: #FFFFFF;
  border: 1px dashed #33A86F;
  border-radius: 12px;
  color: #33A86F;
}
.font_weight_5{
  font-weight: 500 !important;
}
.bg_color{
  background: #A1E2C1 !important;
  
}
.Qualified > div{
  height: 150px;
overflow-y: auto;
}
.Qualified ::-webkit-scrollbar {
  width: 8px;
}
/* Track */
.Qualified ::-webkit-scrollbar-track {
  background: #8DD0AE;
  box-shadow: inset 0px 4px 4px #7BD1A5;
  border-radius: 33px;
} 
/* Handle */
.Qualified ::-webkit-scrollbar-thumb {
  background: #FFF29C; 
  border-radius: 10px;
}
/* Handle on hover */
.Qualified ::-webkit-scrollbar-thumb:hover {
  background: #FFF29C; 
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none !important;
}
/* ---------STAPPER----- */
.Numbers{
      display: none !important;
      /* background-color:  red !important; */
}
.bg-tranparent
{
  background-color: transparent !important;
}
.bg-with-shadow{
  box-shadow: 0px 15px 32px #7ECDA5 !important; background-color: transparent !important;
  border-radius: 22px;
  transition: ease-in-out .2s;
}
.application-fieled .input_field {
margin-top: 6px !important;
  border-left:  1px solid #D7D7D7 !important;
}
.chose_file {
margin-top: 6px !important;
  
}
.cursor-pointer{
  cursor: pointer;
}
.bg-file-upload{
  background-color: #F2F2F2;
  border-radius: 12px;
  cursor: default !important;
}
.chose_file .DYXVr {
  width: 100% !important;
  max-width: 100% !important;
  border:  none !important;
}
.chose_file .row{
  cursor: pointer !important;
}
.bg-file-upload button{
  border: 1px solid #009934 !important;
  border-radius: 7px;
  padding: 5px 10px;
}
.application-fieled{
  z-index: 3;
  position: relative;
}
.chose_file label{
  width: 100% !important;
}
/* ---about-section-css-- */
.colorwhite{
  background: #FFFFFF;
box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
border-radius: 22px;
}
.second-Section{
  background: linear-gradient(180deg, rgba(161,226,193,1) 50%, rgba(255,255,255,1) 50%);
}
/* section-services-- */
.colorgray{
  color: 
  #868686;
}
.color_gray{
  color: #525252 !important;
}
.colorblack{
  color: 
  #000000;
}
.colorgreen{
  color: 
  #009934;
}
.coloryellow{
  color: #FCF09D;
}
/* section-about-slider-css */
.about_slider img{
width: 125px;
height: 125px;
clip-path: circle(50%);
background-color: white !important;
transform: translateY(87px);
}
.about_slider .ineer_slider_text_section{
background-image: url(./assets/pic22.svg);
background-repeat: no-repeat;
background-position: top;
background-size: cover;
border-radius: 24px;
padding-top: 7.8rem;
}
.about_slider .ineer_slider_text_sections{
  background-image: url(./assets/pic23.svg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  border-radius: 24px;
  padding-top: 7.8rem;
  }
.about_slider i{
color: #E1A059;
}
.about_slider{
padding-inline: 30px;
}
.about_slider >div{
position: relative;
z-index: 1;
}
.firstslider .slick-prev {
top: 0 !important;
left: 84%;
z-index: 5;

}

.firstslider .slick-next {
top: 0 !important;
right: 6%;
z-index: 5;

}
.firstslider .slick-prev:before {
opacity: 3;
color: #007755 !important;
background-color: transparent;
padding: 15px 16px !important;
border-radius: 30px; border: 1px solid #007755 !important;
}
.firstslider .slick-next:before {
opacity: 3;
color: #007755 !important;
background-color: transparent;
padding: 15px 16px !important;
border-radius: 30px; border: 1px solid #007755 !important;
}
.firstslider .slick-prev:before, .slick-next:before {
font-family: 'fontawesome' !important;
font-size: 20px;

}
.firstslider .slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
opacity: 1 !important;
color: #B0B0B0 !important;
border: 1px solid #B0B0B0 !important;
}
/* -----media----*/                         
@media (max-width:1400px) {}
@media (min-width:1280px) {}
@media (min-width: 1200px) {
  
}
@media (max-width:1040px) {}
@media (max-width:992px) {
  .backgroundcolor {
    height:auto !important;
  }
  .flex-d-col{
    flex-direction: column-reverse !important;
  }
  .border {
      transform: translate(0px, 0px);
      margin: 22px 0px;
  }
  .inner_line::after {
     display: none !important;
    }
}
@media(max-width:768px) {
  .slick-prev {
      display: none !important;
  }

  .slick-next {
      display: none !important;
  }

  .ineer_box1 {
      padding: 4px !important;
  }
}
@media(max-width:576px) {
  .transform {
       transform: translate(0px, 0px) !important;
      }
      .w-320{
        width: 100% !important;
      }
}
@media(max-width:468px) {

}
@media(max-width:425px) {
  .about_slider img {
    transform: translate(-4px,85px);
    
}
}
@media(max-width:378px) {
  .font_20 {
    font-size: 18px;
}
  .font_18 {
      font-size: 18px;
  }
  .font_16 {
      font-size: 16px;
  }
  .font_14 {
      font-size: 14px;
  }

}
@media(max-width:320px) {

.font_20 {
    font-size: 15px;
}

  .vr {
    display: none !important;
  }
  .font_18 {
      font-size: 18px;
  }
  .font_16 {
      font-size: 16px;
  }
  .font_14 {
      font-size: 14px;
  }
  .flexflow{
    flex-flow: column-reverse !important;
  }
.centermobil{
  text-align: center !important;
}
}

@media(max-width:500px){
  .reverse-sm-column{
    flex-direction: column-reverse;
  }
  .font_32{
    font-size: 25px;
  }
  .phone-number{
    margin-left: 10px;
  }
}